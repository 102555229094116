export let LinkedItemType;
(function (LinkedItemType) {
    LinkedItemType["ArticleCarousel"] = "article_carousel";
    LinkedItemType["ArticleList"] = "article_list";
    LinkedItemType["BlobBanner"] = "blob_banner";
    LinkedItemType["CardList"] = "card_list";
    LinkedItemType["CaseStudyCarousel"] = "case_study_carousel";
    LinkedItemType["CaseStudyList"] = "case_study_list";
    LinkedItemType["CentredContent"] = "centred_content";
    LinkedItemType["CodeBlock"] = "code_block";
    LinkedItemType["ContactCTAs"] = "contact_ctas";
    LinkedItemType["ContactFormWithMap"] = "contact_form_with_map";
    LinkedItemType["ContentGrid"] = "content_grid";
    LinkedItemType["FAQAccordion"] = "faq_accordion";
    LinkedItemType["HeroBanner"] = "hero_banner";
    LinkedItemType["IconGrid"] = "icon_grid";
    LinkedItemType["IconTree"] = "icon_tree";
    LinkedItemType["Image"] = "image";
    LinkedItemType["LogoGrid"] = "logo_grid";
    LinkedItemType["Metrics"] = "metrics";
    LinkedItemType["Pillars"] = "pillars";
    LinkedItemType["PricingMatrix"] = "pricing_matrix";
    LinkedItemType["RequestADemoForm"] = "request_a_demo_form";
    LinkedItemType["RichContent"] = "rich_content";
    LinkedItemType["SimpleBanner"] = "simple_banner";
    LinkedItemType["SplitAccordion"] = "split_accordion";
    LinkedItemType["SplitContent"] = "split_content";
    LinkedItemType["SplitTicks"] = "split_ticks";
    LinkedItemType["TestimonialCarousel"] = "testimonial_carousel";
    LinkedItemType["Video"] = "video";
})(LinkedItemType || (LinkedItemType = {}));
