import { useEffect, useState } from 'react';
const getInitialState = mediaQuery => {
    if (typeof window !== "undefined") {
        return window.matchMedia(mediaQuery).matches;
    }
    return false;
};
function useMediaQuery(mediaQuery) {
    const [isMatch, setIsMatch] = useState(() => getInitialState(mediaQuery));
    useEffect(() => {
        const mediaQueryList = window.matchMedia(mediaQuery);
        const listener = event => {
            setIsMatch(event.matches);
        };
        mediaQueryList.addListener(listener);
        return () => {
            mediaQueryList.removeListener(listener);
        };
    }, []);
    return isMatch;
}
export default useMediaQuery;
