export default function isContentEmpty(content) {
    if (content === undefined) {
        return true;
    }
    if (!(content === null || content === void 0 ? void 0 : content.length)) {
        return true;
    }
    if (content === '<p><br></p>') {
        return true;
    }
    return false;
}
